import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// Vars
import { styleConfig } from '../../../config/config';

const Section: React.SFC<SectionProps> = ({ bg, mh, nopad, children, className }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'block',
      position: 'relative',
      width: '100%',
      minHeight: mh ? mh + 'rem' : '20rem',
      background: bg ? bg : styleConfig.color.bg.white,

      [theme.breakpoints.down('md')]: {
        paddingTop: nopad ? '0' : styleConfig.pad.medium,
        paddingBottom: nopad ? '0' : styleConfig.pad.medium,
      },

      [theme.breakpoints.up('md')]: {
        paddingTop: nopad ? '0' : styleConfig.pad.large,
        paddingBottom: nopad ? '0' : styleConfig.pad.large,
      },

      [theme.breakpoints.up('lg')]: {
        paddingTop: nopad ? '0' : styleConfig.pad.xlarge,
        paddingBottom: nopad ? '0' : styleConfig.pad.xlarge,
      },

      [theme.breakpoints.up('xl')]: {
        paddingTop: nopad ? '0' : styleConfig.pad.xlarge,
        paddingBottom: nopad ? '0' : styleConfig.pad.xlarge,
      },
    },
  }));

  const classes = useStyles();

  return <section className={clsx([classes.root, className])}>{children}</section>;
};

interface SectionProps {
  bg?: string;
  className?: React.ReactNode;
  mh?: string;
  nopad?: boolean;
}

export default Section;
